<template>
  <div>
    <div class="mb-4 flex justify-content-between align-items-center">
      <span class="pr-2 mr-2 font-bold text-2xl">Faktur Pajak Masukan</span>
      <div>
        <i class="pi pi-home mr-2"></i>
        <span class="font-small">/ Pajak / Faktur Pajak Masukan </span>
      </div>
    </div>

    <div class="card bg-white">
      <div class="flex justify-content-end mb-2">
        <Button
          :loading="isLoadingExport"
          icon="pi pi-file-excel"
          label="Export"
          class="ml-2"
          @click="onExport"
        />
      </div>
      <grid-faktur-pajak-masukan
        :items="items"
        :loading="isLoading"
        :total="totalRecords"
        :grid-options="options"
        @edit="onShowEditDialog"
        @request="onRequestData"
      />
    </div>
    <Dialog
      header="Edit Faktur Pajak Masukan"
      v-model:visible="dialogSave"
      :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
      :style="{ width: '60vw' }"
      :modal="true"
    >
      <form-faktur-pajak-masukan
        :loading="isLoadingSave"
        :item="form"
        @save="onSaveData"
      />
    </Dialog>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import useVuelidate from '@vuelidate/core'
import FakturPajakMasukanService from '@/services/FakturPajakMasukanService'
import PajakSeriService from '@/services/PajakSeriService'
import GridFakturPajakMasukan from '@/components/pajak/GridFakturPajakMasukan'
import FormFakturPajakMasukan from '@/components/pajak/FormFakturPajakMasukan'
import errorHandler from '@/helpers/error-handler'

export default {
  setup: () => ({ v$: useVuelidate() }),
  components: {
    GridFakturPajakMasukan,
    FormFakturPajakMasukan,
  },
  data() {
    return {
      dataService: null,
      isLoading: false,
      isLoadingSave: false,
      isLoadingExport: false,
      dialogSave: false,
      items: [],
      invoices: [],
      selects: null,
      totalRecords: 0,
      options: {
        page: 1,
        rows: 10,
        sortField: '',
        sortOrder: -1,
        filters: null,
      },
      submitted: false,
      form: {
        id: 0,
        pi_no: null,
        pi_at: null,
        pi_inv_at: null,
        process_at: null,
        supplier: {
          id: 0,
        },
        dpp: 0,
        ppn: 0,
      },
    }
  },
  created() {
    this.dataService = new FakturPajakMasukanService()
    this.pajakService = new PajakSeriService()
  },
  mounted() {
    this.onLoadGridData()
  },
  computed: {},
  methods: {
    onRequestData(e) {
      this.options = e
      this.onLoadGridData()
    },
    onLoadGridData() {
      const page = this.options.page
      const perPage = this.options.rows
      const sortField = this.options.sortField
      const sortOrder = this.options.sortOrder
      const filters = this.options.filters

      this.isLoading = true
      this.dataService
        .paginate(page, perPage, sortField, sortOrder, filters)
        .then((res) => {
          this.items = res.data.data
          this.totalRecords = res.data.total
          this.isLoading = false
        })
        .catch((err) => {
          errorHandler(err, 'Purchase Order', this)
        })
        .finally(() => (this.isLoading = false))
    },
    onShowEditDialog(data) {
      this.form = Object.assign({}, data)
      this.form.pi_at = this.form.pi_at
        ? dayjs(data.pi_at).format('DD/MM/YYYY')
        : null
      this.form.pi_inv_at = this.form.pi_inv_at
        ? dayjs(data.pi_inv_at).format('DD/MM/YYYY')
        : null
      this.dialogSave = true
    },
    onSaveData(data) {
      let item = {}
      item.pi_id = data.id
      item.pajak_no = data.pajak_no
      item.process_at = dayjs().format('YYYY-MM-DD')
      item.dpp = parseInt(data.dpp_pjk)
      item.ppn = parseInt(data.ppn_pjk)
      item.masa_pajak = parseInt(data.masa_pajak)

      this.isLoadingSave = true
      this.dataService
        .add(item)
        .then((res) => {
          if (res.data.status === 200) {
            this.$toast.add({
              severity: 'success',
              summary: 'Faktur Pajak Masukan',
              detail: 'Faktur Pajak Masukan berhasil disimpan.',
              life: 3000,
            })
            this.onLoadGridData()
            this.dialogSave = false
          }
        })
        .catch((err) => {
          errorHandler(err, 'Faktur Pajak Masukan', this)
        })
        .finally(() => (this.isLoadingSave = false))
    },
    onExport(isFormValid) {
      this.submitted = true

      if (!isFormValid) {
        return
      }
      this.isLoadingExport = true
      this.dataService
        .export()
        .then((res) => {
          const url = window.URL.createObjectURL(new Blob([res.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', 'faktur-pajak-masukan.xls')
          document.body.appendChild(link)
          link.click()
        })
        .catch((err) => {
          errorHandler(err, 'Export Data Faktur Pajak Masukan', this)
        })
        .finally(() => (this.isLoadingExport = false))
    },
    resetForm() {
      this.submitted = false
      this.form.date_to = null
      this.form.date_from = null
    },
  },
}
</script>
