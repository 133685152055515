<template>
  <div>
    <form @submit.prevent="handleSubmit(!v$.$invalid)">
      <div class="card bg-white">
        <div class="grid">
          <div class="col-12 md:col-12">
            <div class="formgrid grid">
              <div class="field col-12 md:col-3">
                <label>Nomor Bukti</label>
                <InputText
                  v-model="form.pi_no"
                  type="text"
                  class="w-full"
                  input-class="w-full"
                  disabled
                />
              </div>
              <div class="field col-12 md:col-3">
                <label>Nomor Faktur</label>
                <InputText
                  v-model="form.pi_inv"
                  type="text"
                  class="w-full"
                  input-class="w-full"
                  disabled
                />
              </div>
              <div class="field col-12 md:col-3">
                <label>Tanggal</label>
                <InputText
                  v-model="form.pi_at"
                  class="w-full"
                  input-class="w-full"
                  dateFormat="dd/mm/yy"
                  disabled
                />
              </div>
              <div class="field col-12 md:col-3">
                <label>Tanggal Faktur</label>
                <InputText
                  v-model="form.pi_inv_at"
                  class="w-full"
                  input-class="w-full"
                  dateFormat="dd/mm/yy"
                  disabled
                />
              </div>
            </div>
          </div>
          <div class="col-12 md:col-12">
            <div class="formgrid grid">
              <div class="field col-12 md:col-6">
                <label>Supplier</label>
                <InputText
                  v-model="form.supplier.nama"
                  type="text"
                  class="w-full"
                  input-class="w-full"
                  disabled
                />
              </div>
              <div class="field col-12 md:col-3">
                <label> No Seri Faktur Pajak </label>
                <InputText
                  v-model="form.pajak_no"
                  type="text"
                  class="w-full"
                  :class="{
                    'p-invalid': v$.form.pajak_no.$invalid && submitted,
                  }"
                  input-class="w-full"
                />
                <small
                  v-if="
                    (v$.form.pajak_no.$invalid && submitted) ||
                    v$.form.pajak_no.$pending.$response
                  "
                  class="p-error"
                  >{{ v$.form.pajak_no.required.$message }}</small
                >
              </div>
              <div class="field col-12 md:col-3">
                <label> Masa Pajak </label>
                <Dropdown
                  v-model="form.masa_pajak"
                  :options="list_masa_pajak"
                  optionValue="id"
                  optionLabel="nama"
                  placeholder="Pilih masa pajak"
                  class="w-full"
                />
              </div>
            </div>
          </div>
          <table width="100%" class="flex justify-content-end">
            <tr>
              <td width="100%">
                <table class="justify-content-end uppercase">
                  <tr>
                    <td colspan="2"></td>
                    <td>Retur</td>
                    <td>
                      <InputText
                        v-model="form.pr_no"
                        type="text"
                        class="w-full"
                        input-class="w-full"
                        disabled
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>DPP</td>
                    <td>
                      <InputNumber inputId="locale-indonesian" locale="en-ID" v-model="form.dpp_pi" :min="0" class="form-edit-faktur" disabled />
                    </td>
                    <td>DPP Retur</td>
                    <td>
                      <InputNumber inputId="locale-indonesian" locale="en-ID" v-model="form.dpp_pr" :min="0" class="form-edit-faktur" disabled />
                    </td>
                  </tr>
                  <tr>
                    <td>PPN</td>
                    <td>
                      <InputNumber inputId="locale-indonesian" locale="en-ID" v-model="form.ppn_pi" :min="0" class="form-edit-faktur" disabled />
                    </td>
                    <td>PPN Retur</td>
                    <td>
                      <InputNumber inputId="locale-indonesian" locale="en-ID" v-model="form.ppn_pr" :min="0" class="form-edit-faktur" disabled />
                    </td>
                  </tr>
                  <tr>
                    <td class="text-right">DPP Pajak</td>
                    <td colspan="3">
                      <InputNumber inputId="locale-indonesian" locale="en-ID" v-model="form.dpp_pjk" :min="0" class="w-full form-edit-faktur"/>
                    </td>
                  </tr>
                  <tr>
                    <td class="text-right">PPN Pajak</td>
                    <td colspan="3">
                      <InputNumber inputId="locale-indonesian" locale="en-ID" v-model="form.ppn_pjk" :min="0" class="w-full form-edit-faktur"/>
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
          </table>
        </div>
        <div class="flex pt-2 justify-content-end align-items-end">
          <Button
            :loading="loading"
            label="Simpan"
            icon="pi pi-check"
            type="submit"
          />
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import { formatCurrency, formatDate } from '@/helpers'
import { helpers, required } from '@vuelidate/validators'

export default {
  setup: () => ({ v$: useVuelidate() }),
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  components: {},
  data() {
    return {
      submitted: false,
      form: this.item,
      list_masa_pajak: [
        { id: "1", nama: 'Januari' },
        { id: "2", nama: 'Februari' },
        { id: "3", nama: 'Maret' },
        { id: "4", nama: 'April' },
        { id: "5", nama: 'Mei' },
        { id: "6", nama: 'Juni' },
        { id: "7", nama: 'Juli' },
        { id: "8", nama: 'Agustus' },
        { id: "9", nama: 'September' },
        { id: "10", nama: 'Oktober' },
        { id: "11", nama: 'November' },
        { id: "12", nama: 'Desember' },
      ],
    }
  },
  created() {},
  computed: {
    totalDPP() {
      return Math.floor(this.form.dpp_pi - this.form.dpp_pr)
    },
    totalPPN() {
      return Math.floor(this.form.ppn_pi - this.form.ppn_pr)
    },
  },
  mounted() {},
  methods: {
    formatCurrency(value, minDigit = 0, maxDigit = 0) {
      return formatCurrency(value, minDigit, maxDigit)
    },
    formatDate(value) {
      return formatDate(value)
    },
    handleSubmit(isFormValid) {
      this.submitted = true
      if (!isFormValid) {
        return
      }
      this.$emit('save', this.form)
    },
  },
  validations() {
    return {
      form: {
        pajak_no: {
          required: helpers.withMessage('No Seri Pajak harus diisi.', required),
        },
      },
    }
  },
}
</script>

<style scoped>
.p-inputnumber-input {
  text-align: right;
}
</style>
